<template>
    <LiefengContent>
        <template v-slot:title>用户统计</template>
        <template v-slot:toolsbarLeft>
            <RadioGroup v-model="seleteType" type="button" button-style="solid" @on-change="changeType">
                <Radio label="按区"></Radio>
                <Radio label="按街道"></Radio>
                <Radio label="按社区"></Radio>
            </RadioGroup>
            <Select v-model="onlineType" filterable transfer>
                <!-- 防止报错，使用了index -->
                <Option value="">全部社区</Option>
                <Option value="1">市级示范社区</Option>
                <Option value="2">区级示范社区</Option>
            </Select>
        </template>
        <template v-slot:toolsbarRight>
            <div class="tooldiv">时间段：</div>
            <DatePicker separator=" 至 " :value="date" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="选择时间段" style="width: 220px" @on-change="changeDate"></DatePicker>

            <Button
                type="info"
                icon="ios-search"
                @click="
                    () => {
                        hadlePageSize({ pageSize: pageSize, page: 1 })
                    }
                "
            >
                查询
            </Button>
            <Button
                type="default"
                icon="md-download"
                @click="
                    () => {
                        downloadData()
                    }
                "
            >
                导出报表
            </Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :tableData="tableData"
                :talbeColumns="talbeColumns"
                :loading="loading"
                :fixTable="true"
                :pageSize="pageSize"
                :total="total"
                :pagesizeOpts="[100,200,500,1000]"
                :curPage="curPage"
                @hadlePageSize="hadlePageSize"
                :showsummary="true"
                :summaryData="summaryData"
                :showIndex="false"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/userscount')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            seleteType: "按区",
            date: ["", ""],
            talbeColumns: [],
            tableData: [],
            loading: false,
            pageSize: 100,
            total: 0,
            curPage: 1,
            summaryData: {},
            onlineType: "",
        }
    },
    methods: {
        changeDate(value) {
            this.date = value
        },
        changeType(value) {
            this.curPage = 1
            this.hadlePageSize({ page: 1, pageSize: this.pageSize })
        },
        downloadData() {
                switch (this.seleteType) {
                    case "按社区":
                         this.$Modal.confirm({
                            title: "温馨提示",
                            content:
                            "<p>您正在导出当前页的数据，是否继续？</p>",
                            onOk: () => {
                            let tHeader = [
                                "区",
                                "街道",
                                "社区",
                                "注册人数",
                                "认证网上家园数"
                            ];
                            let filterVal = [
                                "zone",
                                "street",
                                "community",
                                "register",
                                "onlineHome"
                            ];
                            let tableData = JSON.parse(JSON.stringify(this.tableData));
                            this.$core.exportExcel(tHeader, filterVal, tableData, "报表数据");
                            },
                        })
                        break
                    case "按街道":
                        this.$Modal.confirm({
                            title: "温馨提示",
                            content:
                            "<p>您正在导出当前页的数据，是否继续？</p>",
                            onOk: () => {
                            let tHeader = [
                                "区",
                                "街道",
                                "注册人数",
                                "认证网上家园数"
                            ];
                            let filterVal = [
                                "zone",
                                "street",
                                "register",
                                "onlineHome"
                            ];
                            let tableData = JSON.parse(JSON.stringify(this.tableData));
                            this.$core.exportExcel(tHeader, filterVal, tableData, "报表数据");
                            },
                        })
                        break
                    case "按区":
                        this.$Modal.confirm({
                            title: "温馨提示",
                            content:
                            "<p>您正在导出当前页的数据，是否继续？</p>",
                            onOk: () => {
                            let tHeader = [
                                "区",
                                "注册人数",
                                "认证网上家园数"
                            ];
                            let filterVal = [
                                "zone",
                                "register",
                                "onlineHome"
                            ];
                            let tableData = JSON.parse(JSON.stringify(this.tableData));
                            this.$core.exportExcel(tHeader, filterVal, tableData, "报表数据");
                            },
                        })
                        break
                }
            
           
            // //  location.href=vue.getProxy()["/statistic"].target+"/api/symanage/pc/platform/exportUser?orgLevel="+type+"&startDate="+(this.date[0]==""?"":this.date[0]+" 00:00:00")+"&endDate="+(this.date[1]==""?"":this.date[1]+" 23:59:59");

            // var self = this
            // var url =
            //     vue.getProxy()["/statistic"].target +
            //     "/api/symanage/pc/platform/exportUser?orgLevel=" +
            //     type +
            //     "&startDate=" +
            //     (this.date[0] == "" ? "" : this.date[0] + " 00:00:00") +
            //     "&endDate=" +
            //     (this.date[1] == "" ? "" : this.date[1] + " 23:59:59") +
            //     "&onlineType=" +
            //     this.onlineType
            // let xhr = new XMLHttpRequest()
            // xhr.open("get", url, true) //url为地址链接
            // xhr.setRequestHeader("Content-Type", `application/${type}`)
            // xhr.setRequestHeader("Authorization", window.sessionStorage.getItem("accessToken"))
            // xhr.responseType = "blob"
            // xhr.onload = function () {
            //     if (this.status == 200) {
            //         //接受二进制文件流
            //         var res = this.response
            //         self.downloadExportFile(res, "用户统计", type)
            //     }
            // }
            // xhr.send()
        },
        downloadExportFile(res, fileName, type) {
            const blob = new Blob([res], { type: "application/${type}" })
            const a = document.createElement("a")
            a.href = URL.createObjectURL(blob)
            a.download = fileName + ".xls"
            a.click()
            URL.revokeObjectURL(a.href)
            a.remove()
        },
        hadlePageSize(obj) {
            var type = 3
            switch (this.seleteType) {
                case "按社区":
                    type = 5
                    this.talbeColumns = [
                        {
                            title: "序号",
                            key: "num",
                            align: "center",
                            minWidth: 80,
                        },
                        {
                            title: "区",
                            key: "zone",
                            align: "center",
                            minWidth: 140,
                        },
                        {
                            title: "街道",
                            key: "street",
                            align: "center",
                            minWidth: 180,
                        },
                        {
                            title: "社区",
                            key: "community",
                            align: "center",
                            minWidth: 140,
                        },
                        {
                            title: "注册人数",
                            key: "register",
                            align: "center",
                            minWidth: 160,
                        },
                        // {
                        //   title: "关注公众号数",
                        //   key: "focusGzh",
                        //   align: "center",
                        //   minWidth: 140,
                        // },

                        // {
                        //   title: "实名人数",
                        //   key: "authentication",
                        //   align: "center",
                        //   minWidth: 140,
                        // },
                        {
                            title: "认证网上家园数",
                            key: "onlineHome",
                            align: "center",
                            minWidth: 140,
                        },

                        // {
                        //   title: "管理员数",
                        //   key: "employee",
                        //   align: "center",
                        //   minWidth: 140,
                        // },
                    ]
                    break
                case "按街道":
                    type = 4
                    this.talbeColumns = [
                        {
                            title: "序号",
                            key: "num",
                            align: "center",
                            minWidth: 80,
                        },
                        {
                            title: "区",
                            key: "zone",
                            align: "center",
                            minWidth: 140,
                        },
                        {
                            title: "街道",
                            key: "street",
                            align: "center",
                            minWidth: 180,
                        },
                        {
                            title: "注册人数",
                            key: "register",
                            align: "center",
                            minWidth: 160,
                        },
                        // {
                        //   title: "关注公众号数",
                        //   key: "focusGzh",
                        //   align: "center",
                        //   minWidth: 140,
                        // },

                        // {
                        //   title: "实名人数",
                        //   key: "authentication",
                        //   align: "center",
                        //   minWidth: 140,
                        // },
                        {
                            title: "认证网上家园数",
                            key: "onlineHome",
                            align: "center",
                            minWidth: 140,
                        },

                        // {
                        //   title: "管理员数",
                        //   key: "employee",
                        //   align: "center",
                        //   minWidth: 140,
                        // },
                    ]
                    break
                case "按区":
                    type = 3
                    this.talbeColumns = [
                        {
                            title: "序号",
                            key: "num",
                            align: "center",
                            minWidth: 80,
                        },
                        {
                            title: "区",
                            key: "zone",
                            align: "center",
                            minWidth: 140,
                        },
                        {
                            title: "注册人数",
                            key: "register",
                            align: "center",
                            minWidth: 160,
                        },
                        // {
                        //   title: "关注公众号数",
                        //   key: "focusGzh",
                        //   align: "center",
                        //   minWidth: 140,
                        // },

                        // {
                        //   title: "实名人数",
                        //   key: "authentication",
                        //   align: "center",
                        //   minWidth: 140,
                        // },
                        {
                            title: "认证网上家园数",
                            key: "onlineHome",
                            align: "center",
                            minWidth: 140,
                        },

                        // {
                        //   title: "管理员数",
                        //   key: "employee",
                        //   align: "center",
                        //   minWidth: 140,
                        // },
                    ]
                    break
            }
            this.loading = true
            this.$get("/statistic/api/symanage/pc/platform/selectUserPage", {
                pageSize: obj.pageSize,
                page: obj.page,
                orgLevel: type,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                startDate: this.date[0] == "" ? "" : this.date[0] + " 00:00:00",
                endDate: this.date[1] == "" ? "" : this.date[1] + " 23:59:59",
                onlineType:this.onlineType
            })
                .then(res => {
                    if (res.code == 200 && res.data && res.data.page && res.data.page.dataList) {
                        console.log(res)
                        this.tableData = res.data.page.dataList.map((item,index)=>{
                            return{
                                ...item,
                                num:index+1
                            }
                        })
                        this.pageSize = res.data.page.pageSize
                        this.total = res.data.page.maxCount
                        this.curPage = res.data.page.currentPage
                        let register = 0 ,focusGzh = 0,authentication=0,onlineHome=0,employee=0
                        var sums = {}
                        this.tableData.map(item =>{
                            register = register + item.register
                            focusGzh = focusGzh + item.focusGzh
                            authentication = authentication + item.authentication
                            onlineHome = onlineHome + item.onlineHome
                            employee = employee + item.employee

                        })
                        switch (this.seleteType) {
                case "按社区":
                    if (res.data.total) {
                            sums["num"] = {
                                key: "num",
                                value: "合计",
                            }
                            sums["zone"] = {
                                key: "zone",
                                value: "",
                            }
                            sums["street"] = {
                                key: "street",
                                value: "",
                            }
                            sums["community"] = {
                                key: "community",
                                value: "",
                            }
                            sums["register"] = {
                                key: "register",
                                value: register,
                            }
                            sums["onlineHome"] = {
                                key: "onlineHome",
                                value:onlineHome,
                            }
                        }
                    break
                case "按街道":
                    if (res.data.total) {
                            sums["num"] = {
                                key: "num",
                                value: "合计",
                            }
                            sums["zone"] = {
                                key: "zone",
                                value: "",
                            }
                            sums["street"] = {
                                key: "street",
                                value: "",
                            }
                            sums["register"] = {
                                key: "register",
                                value: register,
                            }
                            sums["onlineHome"] = {
                                key: "onlineHome",
                                value:onlineHome,
                            }
                        }
                    break
                case "按区":
                    if (res.data.total) {
                            sums["num"] = {
                                key: "num",
                                value: "合计",
                            }
                            sums["zone"] = {
                                key: "zone",
                                value: "",
                            }
                            sums["register"] = {
                                key: "register",
                                value: register,
                            }
                            sums["onlineHome"] = {
                                key: "onlineHome",
                                value:onlineHome,
                            }
                        }
                    break
                 }
                        
                        this.summaryData = sums
                    } else {
                        this.tableData = []
                        this.summaryData = {}
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败",
                    })
                })
        },
    },
    mounted() {
        this.hadlePageSize({ page: 1, pageSize: this.pageSize })
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
/deep/ .ivu-radio-group{
    display: flex;
    margin-right: 20px;
}
</style>